import React, { useState, useEffect } from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'
import { StaticImage, getImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

// Analytics
import Gtm from '@components/utility/GTM.js'

import './styles.scss'
//Components
import SubNav from '@components/subNav/subNav.jsx'
import Hero from '@components/hero/hero.jsx'
import Portrait from '@components/portrait/portrait.jsx'
import Accordion from "@components/accordion/Accordion.jsx"


export const Head = () => (
  <>
    <script type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "http://schema.org",
          "type": "VideoObject",
          "name": "Carly's ALL IN® for Lupus Nephritis Story",
          "description": "Watch Carly talk about her daily journey of living with lupus nephritis and ways she better manages her health and wellness.",
          "thumbnailUrl": "https://www.allinforlupusnephritis.com/static/8d16435faef929d0d21c0b675d42cd50/0fafe/thumbnail-carly.webp",
          "uploadDate": "2022-07-12",
          "embedUrl": "https://www.allinforlupusnephritis.com/personal-stories",
          "duration": "PT4M17S",
        })
      }}
    />
    <script type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "http://schema.org",
          "type": "VideoObject",
          "name": "Sara's ALL IN® for Lupus Nephritis Story",
          "description": "Watch how Sara learns to adjust her lifestyle to accommodate for her lupus nephritis diagnosis.",
          "thumbnailUrl": "https://www.allinforlupusnephritis.com/static/eaf4b6ef83d2f76aedd9d3ee0530fa08/0fafe/thumbnail-sara.webp",
          "uploadDate": "2022-07-12",
          "embedUrl": "https://www.allinforlupusnephritis.com/personal-stories",
          "duration": "PT4M20S",
        })
      }}
    />
    <script type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "http://schema.org",
          "type": "VideoObject",
          "name": "Gabrielle's ALL IN® for Lupus Nephritis Story",
          "description": "Gabrielle, who is on dialysis learns to live a fulfilling life with the help of her husband and healthcare team.",
          "thumbnailUrl": "https://www.allinforlupusnephritis.com/static/63edddd87305c80e2e1d831954d518a2/0fafe/thumbnail-gabrielle.webp",
          "uploadDate": "2022-07-12",
          "embedUrl": "https://www.allinforlupusnephritis.com/personal-stories",
          "duration": "PT3M47S",
        })
      }}
    />
    <script type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "http://schema.org",
          "type": "VideoObject",
          "name": "Dina's ALL IN® for Lupus Nephritis Story | Part 1",
          "description": "Dina speaks about her challenging path with lupus nephritis and her goal of not letting her diagnosis define her.",
          "thumbnailUrl": "https://www.allinforlupusnephritis.com/static/8cedbe7ed18e9b0ee645f71072a6d98c/0fafe/thumbnail-dina1.webp",
          "uploadDate": "2022-07-12",
          "embedUrl": "https://www.allinforlupusnephritis.com/personal-stories",
          "duration": "PT3M22S",
        })
      }}
    />
    <script type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "http://schema.org",
          "type": "VideoObject",
          "name": "Dina's ALL IN® for Lupus Nephritis Story | Part 2",
          "description": "Dina talks about her desire to become pregnant, which becomes a journey filled with both fear and hope.",
          "thumbnailUrl": "https://www.allinforlupusnephritis.com/static/b80d6df720ae4738aab19afacd2b6fb1/0fafe/thumbnail-dina2.webp",
          "uploadDate": "2022-07-12",
          "embedUrl": "https://www.allinforlupusnephritis.com/personal-stories",
          "duration": "PT3M36S",
        })
      }}
    />
    <script type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "http://schema.org",
          "type": "VideoObject",
          "name": "Nas & Pam's ALL IN® for Lupus Nephritis Story",
          "description": "Nas discovers a new outlook on life with the support of his mom, after an unexpected diagnosis of lupus nephritis and lupus.",
          "thumbnailUrl": "https://www.allinforlupusnephritis.com/static/53ada150d8ba95a7d68f5e171c17a13d/0fafe/thumbnail-nas-pam.webp",
          "uploadDate": "2022-07-12",
          "embedUrl": "https://www.allinforlupusnephritis.com/personal-stories",
          "duration": "PT4M19S",
        })
      }}
    />
  </>
)
// markup
const PersonalStoriesPage = ({ data }) => {
  const PamPortrait = getImage(data.portraitPam)
  const JasminePortrait = getImage(data.portraitJasmine)
  const HeroImage = getImage(data.hero)

  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const [videoURL, setVideoURL] = useState("")
  //fix logic
  const displayVideo = (e) => {
    setVideoURL(e.currentTarget.getAttribute("videoSrc"))
    setIsVideoVisible(true)
    document.querySelector("body").classList.add("modal-open")
  }

  const hideVideo = (e) => {
    setIsVideoVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }

  useEffect(() => {
    if (isVideoVisible) {
      const video = document.querySelector("video");
      if (video) {
        video.focus();
        Gtm.reset();
        Gtm.initVideo(video);
      } 
    }
  }, [isVideoVisible])
  
  useEffect(() => {
    document.querySelector("body").addEventListener('keydown', (e)=>{if(e.code === 'Escape') hideVideo()})
  }, [])

  useEffect(() => {
    document.querySelector('body').addEventListener('click', (e) => {
      document.getElementById(e.target?.href?.split("#")[1])?.focus();
      }
    )
}, []);

  return (
    <Layout addedClass="page--personal-stories">
      {isVideoVisible &&
        <div className="video-player__container">
          <div className="video-player__background" onClick={hideVideo}></div>
          <video src={videoURL} disablePictureInPicture type="video/mp4" controls></video>
        </div>
      }
      <DocumentHeadMeta title='Personal Stories | ALL IN&reg; for Lupus Nephritis' description='Hear the personal stories and experiences of people who are living with lupus nephritis. Watch videos where they share their challenges, discoveries, and triumphs.' canonicalURL='https://www.allinforlupusnephritis.com/personal-stories' />
      <SubNav>
        <AnchorLink to="/personal-stories#Personal-Stories">Personal Stories</AnchorLink>
        <AnchorLink to="/personal-stories#For-Care-Partners">For Care Partners</AnchorLink>
        <AnchorLink to="/personal-stories#Connect-With-Our-Community">Connect With Our Community</AnchorLink>
      </SubNav>
      <Hero heroImage={HeroImage} imgAlt={""}>
        <h1>Personal Stories: Living with Lupus Nephritis</h1>
        <p><strong>Hear from others who are living with lupus nephritis as they share their challenges, discoveries, and triumphs in the videos below.</strong></p>
      </Hero>
      <section id="Personal-Stories" className="bg-light-blue">
        <div className="container">
          <div className="video-row">
            <div className="video-row__item">
              <div className="thumbnail" tabindex="0" videoSrc="/assets/videos/48_CARLY_FINAL_F_res.mp4" role="button" onKeyDown={(e)=>{if(['Enter','NumpadEnter','Space'].includes(e.code)) displayVideo(e)}} onClick={displayVideo}><StaticImage src="../../images/personal-stories/thumbnail-carly.png" className="" quality="90" placeholder="NONE" alt="Watch Carly's video" width={360}></StaticImage></div>
              <div className="text">
                <div className="title">Carly</div>
                <div className="description">A determined woman talks through her daily journey of living with lupus nephritis and the ways she tries to better manage her health and wellness.</div>
              </div>
            </div>
            <div className="video-row__item">
              <div className="thumbnail" tabindex="0" videoSrc="/assets/videos/210_SARA_FINAL_F_med_res.mp4" role="button" onKeyDown={(e)=>{if(['Enter','NumpadEnter','Space'].includes(e.code)) displayVideo(e)}} onClick={displayVideo}><StaticImage src="../../images/personal-stories/thumbnail-sara.png" className="" quality="90" placeholder="NONE" alt="Watch Sara's video" width={360}></StaticImage></div>
              <div className="text">
                <div className="title">Sara</div>
                <div className="description">Leaving a job she loved at age 30 because of lupus nephritis, a professional woman learns to adjust her lifestyle to accommodate for her diagnosis.</div>
              </div>
            </div>
            <div className="video-row__item">
              <div className="thumbnail" tabindex="0" videoSrc="/assets/videos/gabrielle20191108-24721-1ijtsui.mp4" role="button" onKeyDown={(e)=>{if(['Enter','NumpadEnter','Space'].includes(e.code)) displayVideo(e)}} onClick={displayVideo}><StaticImage src="../../images/personal-stories/thumbnail-gabrielle.png" className="" quality="90" placeholder="NONE" alt="Watch Gabrielle's video" width={360}></StaticImage></div>
              <div className="text">
                <div className="title">Gabrielle</div>
                <div className="description">A woman on dialysis learns to live a fulfilling life with the help of her husband and healthcare team.</div>
              </div>
            </div>
          </div>
          <div className="video-row">
            <div className="video-row__item">
              <div className="thumbnail" tabindex="0" videoSrc="/assets/videos/dina20191108-24721-1v98lfy.mp4" role="button" onKeyDown={(e)=>{if(['Enter','NumpadEnter','Space'].includes(e.code)) displayVideo(e)}} onClick={displayVideo}><StaticImage src="../../images/personal-stories/thumbnail-dina1.png" className="" quality="90" placeholder="NONE" alt="Watch Dina's video | Part 1" width={360}></StaticImage></div>
              <div className="text">
                <div className="title">Dina (Part 1)</div>
                <div className="description">A young woman speaks about her challenging path with lupus nephritis, getting a lupus diagnosis, and her goal of not letting her diagnosis define her.</div>
              </div>
            </div>
            <div className="video-row__item">
              <div className="thumbnail" tabindex="0" videoSrc="/assets/videos/39_DINA_FINAL_F_res.mp4" role="button" onKeyDown={(e)=>{if(['Enter','NumpadEnter','Space'].includes(e.code)) displayVideo(e)}} onClick={displayVideo}><StaticImage src="../../images/personal-stories/thumbnail-dina2.png" className="" quality="90" placeholder="NONE" alt="Watch Dina's video | Part 2" width={360}></StaticImage></div>
              <div className="text">
                <div className="title">Dina (Part 2)</div>
                <div className="description">The desire to become pregnant becomes a journey filled with both fear and hope.</div>
              </div>
            </div>
            <div className="video-row__item">
              <div className="thumbnail" tabindex="0" videoSrc="/assets/videos/110_NAS-PAM_FINAL_F_res.mp4" role="button" onKeyDown={(e)=>{if(['Enter','NumpadEnter','Space'].includes(e.code)) displayVideo(e)}} onClick={displayVideo}><StaticImage src="../../images/personal-stories/thumbnail-nas-pam.png" className="" quality="90" placeholder="NONE" alt="Watch Nas &amp; Pam's video" width={360}></StaticImage></div>
              <div className="text">
                <div className="title">Nas & Pam</div>
                <div className="description">Following an unexpected diagnosis of lupus nephritis and lupus, a young man discovers a new outlook on life with the loving support of his mom.</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <Portrait name="Jasmine" description="diagnosed with lupus nephritis in 2018" imgSrc={JasminePortrait} imgAlt=""></Portrait>
          <p>"What I have learned through living with lupus and lupus nephritis is that perspective, and self-talk is life. Even though I've got this rain cloud following me, I never turn away the opportunity to be someone's sunshine. These are the things that keep me out of my head."</p>
        </div>
      </section>
      <section id="For-Care-Partners" className="bg-white">
        <div className="container container--narrow">
          <h2>Caring for your loved one (and yourself)</h2>
          <p className="text-left">Being a care partner can be a rewarding experience—but it does come with its own set of challenges. You may be able to help your loved one by attending doctor’s appointments with them. One of the best ways to support the person in your life living with lupus nephritis is to recognize the signs that your loved one is not feeling well.</p>
          <div className="image-container">
            <StaticImage src="../../images/personal-stories/caring-for-yourself.png" className="" quality="100" placeholder="NONE" alt="" width={836}></StaticImage>
            <p className="reference">Nas, diagnosed with lupus nephritis in 2017, and his care partner, Pam</p>
          </div>
          <p className="text-left">It can also help to go with your loved one to doctor's appointments. Healthcare visits can sometimes feel overwhelming to a person living with an illness. By attending appointments with your loved one, you not only add valuable moral support, but you also can ask questions, take notes and provide additional information to the doctor or specialist. At the end of the day, one of the best ways to support your loved one is to simply listen. Oftentimes, listening can be just as helpful, if not more so, than offering advice.</p>
        </div>
        <div className="container container--narrow container--taking-care">
          <h2>Taking care of yourself</h2>
          <p className="text-left">As you care for your loved one, don't forget to pay attention to your own needs. Care partner burnout is a fairly common occurrence among those caring for someone with a disease.</p>
          <div className="list-container">
            <h3>To support yourself:</h3>
            <ul className="standard-list">
              <li>Talk to someone—friends, family, a therapist, another care partner, support group member&#8288;&mdash;&#8288;about how you're feeling</li>
              <li>Set reasonable expectations for your lifestyle, knowing that flexibility is key when supporting a loved one living with lupus nephritis</li>
              <li>Have outlets for your own physical, mental and emotional wellbeing, such as meditation or exercise</li>
              <li>Communicate your own needs in a clear, assertive, and constructive manner</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <Portrait name="Pam" description="Care partner of Nas, diagnosed with lupus nephritis in 2017" imgSrc={PamPortrait} imgAlt=""></Portrait>
          <p>"I wanted to cry. I wanted to break down. I couldn't though, because if he wasn't, I didn't feel like I could. He was going through this mentally, physically, and emotionally. I was just going through this emotionally."</p>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow">
          <h2>Resources for care partners</h2>
          <p className="text-left"><a data-modal-id='exit-modal' className="link--purple" href="https://www.beyondmybattle.org/">Beyond My Battle</a> aims to reduce the stress of serious illness, rare disease, and disability through emotional support and educational resources rooted in mindfulness, awareness and compassion.</p>
          <p className="text-left"><a data-modal-id='exit-modal' className="link--purple" href="https://www.lupus.org/resources/for-caregivers">The Lupus Foundation of America</a> provides additional information and resources specifically for care partners.</p>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <StaticImage src="../../images/kit-icon.png" className="" quality="85" placeholder="NONE" alt="Image of ALL IN&reg; Lupus Nephritis Awareness Kit" width={206} ></StaticImage>
          <h2>Get a guide for care partners.</h2>
          <p className="less-margin">Caring for someone with lupus nephritis can be overwhelming, but you're not alone. The Lupus Nephritis Awareness Kit offers support for both you and your loved one, along with other helpful resources.</p>
          <Link to="/register" className="cta--purple cta--body">Get Your Kit</Link>
        </div>
      </section>
      <section id="Connect-With-Our-Community" className="bg-white">
        <div className="container container--narrow">
          <h2>Connect with our community</h2>
          <p className="text-left">If you're looking for more information about lupus or lupus nephritis, or you want to connect with other members of the community, the following groups may be able to help.</p>
          <p className="text-left"><strong>Please note that Aurinia does not endorse or monitor the activities and content of advocacy groups, their websites, or third-party online communities.</strong></p>
          <Accordion title="Online Communities" subTitle="Connect with other people living with lupus and lupus nephritis online.">
            <a data-modal-id='exit-modal' href="https://www.facebook.com/ALLINforLN">ALL IN&reg; on Social Media</a>
            <p className="item">Stay up to date on resources for lupus nephritis, helpful tips, inspiring stories, and more. Follow ALL IN on Facebook to find strength in our community.</p>
            <a data-modal-id='exit-modal' href="https://www.lupuschat.org/">LupusChat</a>
            <p className="item">LupusChat is a patient-driven online community established in 2012, designed to educate all entities in the healthcare sector, thus sparking innovative discussions and breaking restrictive barriers between patients and various healthcare stakeholders.</p>
            <a data-modal-id='exit-modal' href="https://www.morethanlupus.com/">More Than Lupus</a>
            <p className="item">Created by a lupus advocate who has lived with lupus for 27&nbsp;years, this site offers a space for those living with lupus to connect, grow, and inspire others throughout their lupus journey.</p>
            <a data-modal-id='exit-modal' href="https://www.mylupusteam.com">MyLupusTeam</a>
            <p className="item">A free social network designed for those living with lupus.</p>
            <a data-modal-id='exit-modal' href="https://themighty.com/">The Mighty</a>
            <p className="item">The Mighty is a digital health community that empowers and connects people dealing with health challenges like lupus nephritis. The site features a story from Gabrielle, who manages a career, travel and volunteer advocacy while living with lupus nephritis.</p>
          </Accordion>

          <Accordion title="National Groups" subTitle="Take a look at the organizations serving the lupus community all over the country.">
            <a data-modal-id='exit-modal' href="http://www.aakp.org">American Association of Kidney Patients</a>
            <p className="item">The American Association of Kidney Patients is dedicated to improving the quality of life for people with kidney disease through education, advocacy and management along with fostering support communities.</p>
            <a data-modal-id='exit-modal' href="https://www.beyondmybattle.org/">Beyond My Battle</a>
            <p className="item">A nonprofit organization committed to reducing the stress of serious illness, rare disease, and disability through emotional support and educational resources rooted in mindfulness, awareness and compassion.</p>
            <a data-modal-id='exit-modal' href="https://blackhealthmatters.com/">Black Health Matters</a>
            <p className="item">Black Health Matters collaborates with top physicians, community advocates and health companies to raise awareness to risk factors and chronic diseases—including lupus and lupus nephritis, that disproportionately affect African Americans. They also partner with community leaders and groups (fraternities, sororities, civic organizations, etc.) that are committed to social action; helping expand reach to make health a priority in the African American community.</p>
            <a data-modal-id='exit-modal' href="https://www.ladainc.org/">Lupus and Allied Diseases Association</a>
            <p className="item">An all-volunteer national nonprofit organization dedicated to enhancing the quality of life for those impacted by lupus and allied diseases and other conditions of unmet need by fostering collaboration among stakeholders and promoting innovative advocacy, awareness and biomedical research programs.</p>
            <a data-modal-id='exit-modal' href="http://www.lupus.org">Lupus Foundation of America</a>
            <p className="item">A national organization devoted to improving the quality of life for all people affected by lupus through programs of research, education, support, and advocacy.</p>
            <p className="item"><a data-modal-id='exit-modal' href="https://resources.lupus.org/">National Resource Center on Lupus:</a> Developed by the Lupus Foundation of America, the National Resource Center on Lupus provides up-to-date information and resources for the lupus community.</p>
            <p className="item"><a data-modal-id='exit-modal' href="http://www.lupus.org/chapters">Local Chapters:</a> Find state and regional chapters of the Lupus Foundation of America using this interactive map.</p>
            <a data-modal-id='exit-modal' href="http://www.lupusresearch.org">Lupus Research Alliance</a>
            <p className="item">A nonprofit organization committed to reducing the stress of serious illness, rare disease, and disability through emotional support and educational resources rooted in mindfulness, awareness and compassion.</p>
            <a data-modal-id='exit-modal' href="http://www.kidney.org">National Kidney Foundation</a>
            <p className="item">An organization in the U.S. dedicated to the awareness, prevention, and treatment of kidney disease for hundreds of thousands of healthcare professionals, millions of patients and their families, and tens of millions of Americans at risk.</p>
            <a data-modal-id='exit-modal' href="https://nephcure.org/">Nephcure Kidney International</a>
            <p className="item">A nonprofit organization committed to reducing the stress of serious illness, rare disease, and disability through emotional support and educational resources rooted in mindfulness, awareness and compassion.</p>
          </Accordion>

          <Accordion title="Regional and Local Groups" subTitle="Find organizations in your area for additional support.">
            <a data-modal-id='exit-modal' href="https://kaleidoscopefightinglupus.org/">Kaleidoscope Fighting Lupus</a>
            <p className="item">Kaleidoscope Fighting Lupus educates the public and informs the medical community about lupus, advocates for an earlier life-saving diagnosis, supports people living with lupus and collaborates with government and foundations to fund research towards a cure.</p>
            <a data-modal-id='exit-modal' href="https://looms4lupus.org/">Looms for Lupus</a>
            <p className="item">Looms for Lupus is a nonprofit organization that provides awareness, outreach and resources to minority families, those affected by lupus, their families and caregivers. Looms for Lupus provides in-person bilingual support groups, education and outreach through multiple avenues such as hands-on workshops, informational clinics, bilingual resources, and psychosomatic support groups.</p>
            <a data-modal-id='exit-modal' href="https://lupusla.org/">Lupus LA</a>
            <p className="item">Lupus LA is a nonprofit health organization, dedicated to finding the causes of and a cure for lupus while providing support, services and hope to all who have lupus. Lupus LA raises funds for supporting medical research, as well as provides patient services, and promotes awareness and advocacy.</p>
            <a data-modal-id='exit-modal' href="https://www.lupusil.org/">Lupus Society of Illinois</a>
            <p className="item">The Lupus Society of Illinois promotes lupus awareness and complements the work of health care professionals by providing personalized resources for the lupus community while supporting research.</p>
            <a data-modal-id='exit-modal' href="https://tennesseekidneyfoundation.org/">Tennessee Kidney Foundation</a>
            <p className="item">Tennessee Kidney Foundation (TKF) is dedicated to empowering and supporting those at risk or affected by kidney disease and envisions a Tennessee where everyone who needs care receives care. TKF provides direct assistance programs to facilitate access to care for kidney patients as well as educational resources and preventive screenings for the community.</p>
          </Accordion>
          <p className="text-left">Do you know of any other lupus or lupus nephritis groups that may be missing from this list? <Link to="/contact" className="">Let us know.</Link></p>
        </div>
      </section>
    </Layout>
  )
}

export default PersonalStoriesPage

export const pageQuery = graphql`
  query PersonalStoriesQuery {
    portraitPam: file(relativePath: {eq: "portrait-Pam.png"}) {
      childImageSharp {
        gatsbyImageData(height:240)
      }
    }
    portraitJasmine: file(relativePath: {eq: "portrait-Jasmine.png"}) {
      childImageSharp {
        gatsbyImageData(height:240)
      }
    }
    hero: file(relativePath: {eq: "personal-stories/hero-personal-stories.png"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
  }
`